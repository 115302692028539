<template>
  <b-container class="page-container">
    <Breadcrumbs currentpage="Aanvraag succesvol" />
    <b-alert v-if="invalidLink || !code" show variant="warning"
      ><font-awesome-icon icon="exclamation-circle" /> Je email bevesitings link
      lijkt onjuist of is verlopen, je account is niet geactiveerd.
    </b-alert>
    <b-row v-if="!invalidLink && code && doneLoading && !canLogin">
      <b-col xs="12" md="8">
        <h1 class="pb-3">Bevestiging van je accountaanvraag</h1>
        <h4 class="pb-3">Bedankt voor je aanvraag!</h4>
        <p>
          We controleren of je vereniging in aanmerking komt om materiaal te
          ontlenen bij de uitleendienst. Hiervoor contacteren we je lokaal
          bestuur, wat enkele dagen in beslag kan nemen.
        </p>

        <h4>In tussentijd al eens snuisteren door ons aanbod?</h4>
        <router-link class="btn btn-primary mt-3" :to="{ name: 'index' }"
          >Bekijk onze catalogus</router-link
        >
      </b-col>
    </b-row>
    <b-row v-if="!invalidLink && code && doneLoading && canLogin">
      <b-col xs="12" md="8">
        <h1 class="pb-3">Bevestiging van je emailadres</h1>
        <h4 class="pb-3">Bedankt voor het verifieren van je emailadres!</h4>
        <p>
          Je account is nu geactiveerd en het is nu mogelijk om in te
          <router-link :to="{ name: 'login' }">loggen</router-link>.
        </p>
        <p>
          Heb je vragen over je account? Neem dan contact op met
          <a href="mailto:uitleendienst@vlaamsbrabant.be">
            uitleendienst@vlaamsbrabant.be
          </a>
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
const { confirmUser } = require('@/services/AuthorizationService')
import Breadcrumbs from '@/components/header/BreadCrumbs'
export default {
  components: { Breadcrumbs },
  data() {
    return {
      invalidLink: false,
      doneLoading: false,
      canLogin: false
    }
  },
  computed: {
    code: function() {
      return this.$route.params.code || false
    }
  },
  mounted: async function() {
    const result = await confirmUser({ code: this.code })
    if (result.Message == 'InvalidModelState') {
      this.invalidLink = true
    }

    if (result.data == 'Exception_RegistrationConfirmWrongInput') {
      this.invalidLink = true
    }

    if (result.data === 'Succes_RegistrationAndUserConfirm') {
      this.canLogin = true
    }

    this.doneLoading = true
  }
}
</script>

<style scoped lang="scss">
.page-container {
  h4 {
    font-size: 35px;
  }
  p {
    font-size: 18px;
  }
}
</style>
